import React from 'react';

import Layout from '../components/Layout';

const IndexPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header></header>
      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Al-Itqan Center</h3>
          AlItqan is a registered 501(c)(3). Not only will you be supporting a
          noble cause, your donation will also be tax-deductible. You can now
          easily and securely donate through our service provider and be
          confident that your funds will support the growth of your community.
          As a non-profit, we rely on donations from the community to grow, so
          we hope that the generosity of our community (and elsewhere!) will
          continue to support us while we work hard to educate and serve them.
          <section className="features">
            <article>
              <ul class="alt">
                <li>
                  <a
                    className="button primary fit"
                    onClick={() =>
                      window.open(
                        'https://checkout.square.site/buy/PVKESKX4HP5SERK3RR2QHV33'
                      )
                    }
                    aria-label="$50 donation"
                  >
                    $50
                  </a>
                </li>
                <li>
                  <a
                    className="button primary fit"
                    onClick={() =>
                      window.open(
                        'https://checkout.square.site/buy/2WRM2RG6DY3EDORQPFABFZ3V'
                      )
                    }
                    aria-label="$100 donation"
                  >
                    $100
                  </a>
                </li>
                <li>
                  <a
                    className="button primary fit"
                    onClick={() =>
                      window.open(
                        'https://checkout.square.site/buy/MNE2UPGQZUEDYCQPPU6CI643'
                      )
                    }
                    aria-label="$300 donation"
                  >
                    $300
                  </a>
                </li>
                <li>
                  <a
                    className="button primary fit"
                    onClick={() =>
                      window.open(
                        'https://square.link/u/ruYrtyf0'
                      )
                    }
                    aria-label="$500 donation"
                  >
                    $500
                  </a>
                </li>
                <li>
                  <a
                    className="button primary fit"
                    onClick={() =>
                      window.open(
                        'https://checkout.square.site/buy/T6YWM4LX2YVYQUX5S3SYQ6TF'
                      )
                    }
                    aria-label="$1000 donation"
                  >
                    $1000
                  </a>
                </li>
                <li>
                  <a
                    className="button primary fit"
                    onClick={() =>
                      window.open(
                        'https://square.link/u/FF9PlG11'
                      )
                    }
                    aria-label="$2000 donation"
                  >
                    $2000
                  </a>
                </li>
              </ul>
            </article>
          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default IndexPage;
